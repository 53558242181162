/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

/* profile upload image */
.pr-up-img{
	border-radius: 4rem !important;

}

.react-dataTable .column-action svg {
  stroke: #6e6b7b; /* Matches the default react-dataTable icon color */
}

/* Dark Theme - Action Icon Color */
.dark-layout .react-dataTable .column-action svg {
  stroke: #b4b7bd; /* Matches the dark theme react-dataTable icon color */
}